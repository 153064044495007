<template>
   <div class="examinationVisualAcuityHome">
       <div class="content">
           <div class="box">
               <p>裸眼远视力</p>
               <span>OD</span>
<!--             :class="[srjc(postData.S_OP_HI_Far_SCOD, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_Far_SCOD'?'xzs':'']" -->
               <el-input class="input" @keydown.native="keyd($event)" @focus="getgb(postData.S_OP_HI_Far_SCOD, 'S_OP_HI_Far_SCOD')" v-model="postData.S_OP_HI_Far_SCOD" placeholder="请输入内容"></el-input>
               <span>OS</span>
<!--        :class="[srjc(postData.S_OP_HI_Far_SCOS, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_Far_SCOS'?'xzs':'']"     -->
               <el-input class="input" @keydown.native="keyd($event)"  @focus="getgb(postData.S_OP_HI_Far_SCOS, 'S_OP_HI_Far_SCOS')" v-model="postData.S_OP_HI_Far_SCOS" placeholder="请输入内容"></el-input>
           </div>
           <div class="box">
               <p>裸眼近视力</p>
               <span>OD</span>
<!--    :class="[srjc(postData.S_OP_HI_VA_OD, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_VA_OD'?'xzs':'']"          -->
               <el-input @keydown.native="keyd($event)" @focus="getgb(postData.S_OP_HI_VA_OD, 'S_OP_HI_VA_OD')" class="input" v-model="postData.S_OP_HI_VA_OD" placeholder="请输入内容"></el-input>
               <span>OS</span>
<!--      :class="[srjc(postData.S_OP_HI_VA_OS, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_VA_OS'?'xzs':'']"       -->
               <el-input @keydown.native="keyd($event)"  @focus="getgb(postData.S_OP_HI_VA_OS, 'S_OP_HI_VA_OS')" class="input" v-model="postData.S_OP_HI_VA_OS" placeholder="请输入内容"></el-input>
           </div>
           <div class="xz">
               <p>是否戴镜</p>
               <el-radio-group v-model="postData.S_OP_HI_IfGlasses">
                   <el-radio label='1'>有</el-radio>
                   <el-radio label='0'>无</el-radio>
               </el-radio-group>
           </div>
         <div v-if="postData.S_OP_HI_IfGlasses == 1">
           <div class="pjsj">
             <p>旧镜验配时间</p>
             <div>
               <el-radio v-model="pjradio" :label="0">不详</el-radio>
               <el-radio v-model="pjradio" :label="1">具体时间</el-radio>
               <el-date-picker
                   format="yyyy-MM-dd"
                   value-format="yyyy-MM-dd"
                   v-if="pjradio"
                   v-model="value1"
                   type="date"
                   placeholder="选择日期">
               </el-date-picker>
             </div>
           </div>

           <div class="jjxx">
             <p>旧镜信息</p>
             <el-input class="srjj" v-model="postData.S_OP_HI_Frame_note" placeholder="请输入内容"></el-input>
             <el-button type="primary" @click="showdrsb=true">导入设备数据</el-button>
           </div>

           <div class="srA">
             <div>
               <ul class="content-sr w8 padding-20">
                 <li></li>
                 <li>球镜</li>
                 <li>柱镜</li>
                 <li>轴向</li>
                 <li>ADD</li>
                 <li>旧镜VA</li>
                 <li>近用视力</li>
                 <li>瞳距</li>
               </ul>
               <ul class="content-sr w8">
                 <li>OD</li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_SPHOD, -30, 20)?'cws':'', srval==='S_OP_HI_Frame_SPHOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_SPHOD, 'S_OP_HI_Frame_SPHOD', 1)" v-model="postData.S_OP_HI_Frame_SPHOD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_CYLOD, -10, 9)?'cws':'', srval==='S_OP_HI_Frame_CYLOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_CYLOD, 'S_OP_HI_Frame_CYLOD', 1)" v-model="postData.S_OP_HI_Frame_CYLOD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_AxesOD, 0, 180)?'cws':'', srval==='S_OP_HI_Frame_AxesOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_AxesOD, 'S_OP_HI_Frame_AxesOD')" v-model="postData.S_OP_HI_Frame_AxesOD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_AddOD, 0, 10)?'cws':'', srval==='S_OP_HI_Frame_AddOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_AddOD, 'S_OP_HI_Frame_AddOD')" v-model="postData.S_OP_HI_Frame_AddOD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_VAOD, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_Frame_VAOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_VAOD, 'S_OP_HI_Frame_VAOD')" v-model="postData.S_OP_HI_Frame_VAOD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_NearVisionOld_OD, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_NearVisionOld_OD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_NearVisionOld_OD, 'S_OP_HI_NearVisionOld_OD')" v-model="postData.S_OP_HI_NearVisionOld_OD" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_PDOD, 20, 100)?'cws':'', srval==='S_OP_HI_Frame_PDOD'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_PDOD, 'S_OP_HI_Frame_PDOD')" v-model="postData.S_OP_HI_Frame_PDOD" placeholder="请输入内容"></el-input>mm</li>
               </ul>
               <ul class="content-sr w8 padding-20">
                 <li>OS</li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_SPHOS, -30, 20)?'cws':'', srval==='S_OP_HI_Frame_SPHOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_SPHOS, 'S_OP_HI_Frame_SPHOS', 1)" v-model="postData.S_OP_HI_Frame_SPHOS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_CYLOS, -10, 9)?'cws':'', srval==='S_OP_HI_Frame_CYLOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_CYLOS, 'S_OP_HI_Frame_CYLOS', 1)" v-model="postData.S_OP_HI_Frame_CYLOS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_AxesOS, 0, 180)?'cws':'', srval==='S_OP_HI_Frame_AxesOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_AxesOS, 'S_OP_HI_Frame_AxesOS')" v-model="postData.S_OP_HI_Frame_AxesOS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_AddOS, 0, 10)?'cws':'', srval==='S_OP_HI_Frame_AddOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_AddOS, 'S_OP_HI_Frame_AddOS')" v-model="postData.S_OP_HI_Frame_AddOS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_VAOS, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_Frame_VAOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_VAOS, 'S_OP_HI_Frame_VAOS')" v-model="postData.S_OP_HI_Frame_VAOS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_NearVisionOld_OS, 0.01, 1.5)?'cws':'', srval==='S_OP_HI_NearVisionOld_OS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_NearVisionOld_OS, 'S_OP_HI_NearVisionOld_OS')" v-model="postData.S_OP_HI_NearVisionOld_OS" placeholder="请输入内容"></el-input></li>
                 <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_HI_Frame_PDOS, 20, 100)?'cws':'', srval==='S_OP_HI_Frame_PDOS'?'xzs':'']" @focus="getgb(postData.S_OP_HI_Frame_PDOS, 'S_OP_HI_Frame_PDOS')" v-model="postData.S_OP_HI_Frame_PDOS" placeholder="请输入内容"></el-input>mm</li>
               </ul>
             </div>
           </div>
         </div>
       </div>
     <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
     <el-dialog
         title=""
         :visible.sync="showdrsb"
         width="70%"
     >
       <ImportEquipment @getdata="drdata" :types="'LM'"></ImportEquipment>
       <span slot="footer" class="dialog-footer">
         <el-button @click="showdrsb = false">取 消</el-button>
       </span>
     </el-dialog>
   </div>
</template>

<script>
    export default {
        name: "examinationVisualAcuityHome",
        data() {
            return {
                radio: 1,
                chooseid: '00000',
                a: [{name: 'aa'}, {name: 'bbb'}],
                input: '',
                options: [
                    {
                        value: 'yy',
                        label: '无明显受限'
                    },
                    {
                        value: 'bb',
                        label: '无明显受限啊啊啊'
                    },
                    {
                        value: 'cc',
                        label: '无明显受限热热热'
                    },
                ],
                value: [],
                ids: 2,
                postData: {S_OP_HI_IfGlasses: '0'},
              value1: '',
              pjradio: 0,


              showdrsb: false,
              showkeys: false,
              issr: false,
              srval: '',
              keyval: '',
              iszf: 0,
            }
        },
        props: {
          idx: {
            default: -1
          }
        },
      watch: {
        idx: function (n, o) {
          if (n == this.ids && this.chooseid != this.$store.state.physicianVisits.xzConsulting) {
            if (this.$store.state.physicianVisits.xzConsulting) {
              this._api.eyeTest.getVisionTest()
                  .then(res => {
                    if (res.GetListResult) {
                      this.$store.commit('eyeTest/upDatas', this._clJson(res.GetListResult[0].S_OP_Json))
                      this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
                      // console.log(this.postData)
                    }
                    this.ids = n
                    this.chooseid = this.$store.state.physicianVisits.xzConsulting
                    this.$store.commit('eyeTest/upChooseId', this.chooseid)
                  })
            }
          }
        },
        postData: {
          handler(n, o) {
            // console.log(n)
            this.$store.commit('eyeTest/upDatas', n)
          },
          deep: true
        },
        pjradio: function (n, o) {
          if (n) {
            this.postData.S_OP_HI_WearTime = this.value1
          }else {
            this.postData.S_OP_HI_WearTime = '不详'
          }
        },
        value1: function (n) {
          this.postData.S_OP_HI_WearTime = this.value1
        }
      },
      created() {
        document.addEventListener('click', this.getXY, false)
        this.postData = this.$store.state.eyeTest.datas
      },
      methods: {
        keyd(e) {
          // e.returnValue= false
        },

        srjc(val, min, max) {
          if (val) {
            if (val.indexOf('^') > -1) return false
            if (isNaN(Number(val))) return true
            if (Number(val) > Number(max)) return true
            if (Number(val) < Number(min)) return true
            return false
          }
          return false
        },

        getgb(str, key, iszf=0) {
          // console.log(e)
          // document.activeElement.blur();
          this.keyval = str
          this.srval = key
          this.issr = true
          this.iszf = iszf
        },

        getXY(e) {
          if (this.issr) {
            this.$store.commit('isShow/upkeyh', e.y)
            this.showkeys = true
            this.issr = false
          }else  {
            this.showkeys = false
            this.srval = ''
          }

        },

        changeval(e) {
          this.keyval = e
          this.$set(this.postData, this.srval, e)
          if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
            if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
              this.$set(this.postData, this.srval, '+'+e)
            }
          }
        },

        drdata(data) {
          if (data) {
            this.postData.S_OP_HI_Frame_SPHOD = data.RightSPH?(data.RightSPH).toString():''
            this.postData.S_OP_HI_Frame_CYLOD = data.RightCYL?(data.RightCYL).toString():''
            this.postData.S_OP_HI_Frame_AxesOD = data.RightAxis?(data.RightAxis).toString():''
            this.postData.S_OP_HI_Frame_PDOD = (data.PD/2)?(data.PD/2).toString():''

            this.postData.S_OP_HI_Frame_SPHOS = data.LeftSPH?(data.LeftSPH).toString():''
            this.postData.S_OP_HI_Frame_CYLOS = data.LeftSPH?(data.LeftCYL).toString():''
            this.postData.S_OP_HI_Frame_AxesOS = data.LeftAxis?(data.LeftAxis).toString():''
            this.postData.S_OP_HI_Frame_PDOS = (data.PD/2)?(data.PD/2).toString():''

            this.showdrsb = false
          }
        },
      },
      components: {
        KEYS: () => import('@/components/keyboard/keyHome'),
        ImportEquipment: () => import('@/components/specialSurveys/refractive/importEquipment'),
      },
      beforeDestroy() {
        document.removeEventListener('click', this.getXY)
      }
    }
</script>

<style scoped lang="scss">
    .examinationVisualAcuityHome {
        width: 88vw;
    }
    .content {
        width: 90%;
        margin: 0 auto;
    }
    .box {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-top: 30px;
        .input {
           width: 2rem;
           margin-right: 0.5rem;
        }
        p {padding-right: 40px;};
        span {padding-right: 0.1rem}
    }
    .xz {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-top: 60px;
        p {padding-right: 100px;}
    }
    .srA {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      .content-sr {
        display: flex;
        justify-content: start;
        align-items: center;
        li {
          width: 8vw;
          margin-right: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          input {
            display: block;
            width: 100%;
            outline: none;
            border: 1px solid #000000;
            border-radius: 5px;
            padding: 5px 0;
            text-align: center;
          }
          .syph {
            position: absolute;
            top: -1.4em;
            text-align: center;
            width: 100%;
          }
        }
        li:nth-child(1) {
          width: 4vw;
          justify-content: start;
          margin-right: 5px;
        }
      }
      .w8 { li {width: 7vw;} }
      .tj {
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
          margin-top: 21px;
        }
      }
      .last {
        margin-left: 50px;
        margin-top: 32px;
      }
    }
    ::v-deep .content input:hover {background: #e8e6e6;}
    ::v-deep .xzs input {background: #e8e6e6;}
    ::v-deep .cws input {background: #ef4674;}
    .mar-20 {margin-top: 30px}
    .padding-20{padding-top: 15px;}
    .pjsj {
      display: flex;
      padding-top: 20px;
      align-items: center;
      p {padding-right: 71px;}
    }
    .jjxx {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .srjj {
        width: 35vw;
        margin-left: 100px;
        margin-right: 20px;
      }
    }
</style>
